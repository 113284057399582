import Cookies from "js-cookie";

export const BASE_URL = 'https://api.verifiednetwork.net/api/v1';
const token = Cookies.get('token');
export const TOKEN = token;
export const HEADER = {
    'Authorization': `Bearer ${token}`, 
    'Content-Type': 'application/json', 
};
export const IMAGE_URL = 'https://api.verifiednetwork.net/storage/app/public/images';
