import React, { useEffect } from 'react';

function AutoHttpsLink({ href, children }) {
  useEffect(() => {
    // Check if href starts with "http://" or "www."
    if (href && !href.startsWith('http://') && !href.startsWith('https://') && !href.startsWith('www.')) {
      // Add "https://" to the beginning of the href
      const updatedHref = 'https://' + href;
      const links = document.querySelectorAll(`a[href="${href}"]`);

      // Update all anchor tags with the same href
      links.forEach(link => {
        link.setAttribute('href', updatedHref);
      });
    }
  }, [href]);

  return <a href={href} style={{color:'black'}} target="_blank">{children}</a>;
}

export default AutoHttpsLink;