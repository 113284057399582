import axios from 'axios';
import React, { useEffect, useState } from 'react'
import {  Button, Modal, Form  } from 'react-bootstrap';
import toast from 'react-hot-toast';
import {IoMdAdd} from 'react-icons/io'
import { BASE_URL, HEADER } from '../../../Config';
import { MdDelete } from 'react-icons/md';
import Swal from 'sweetalert2';

const Region = () => {
    const [data, setData] = useState([]);
    const [show, setShow] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [formData, setFormData] = useState(
        {
            region_title: ''
        }
    );
function getAllRegion(){
    setShowLoading(true);
    try {
        axios.get(BASE_URL+'/regions',{
            headers: HEADER
        }).then((res)=>{
            
            console.log(res.data);
            setData(res.data);
            setShowLoading(false);


        }).catch(error =>{
            setShowLoading(false);
            // console.log(error);
        })
    } catch (error) {
        setShowLoading(false);
        // console.log(error);

        
    }


}

function showAlert(e){
    Swal.fire({
    title: 'Are you sure you want to delete it?',
    showDenyButton: true,
    confirmButtonText: 'Yes',
    denyButtonText: `No`,
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      axios.get(BASE_URL+"/delete_regions/"+e,{headers:HEADER}).then((res)=>{
        // console.log(res);
        toast.success("Deleted successfully");
        getAllRegion();
      }).catch(error=>{
        // console.log(error);
        toast.error("Unable to delete, please try again later");
      });
      // Swal.fire('Saved!', '', 'success')
    } else if (result.isDenied) {
      // Swal.fire('Changes are not saved', '', 'info')
    }
  })
   }
useEffect(()=>{
    getAllRegion();
},[])
    function handleChange(e){
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    }

    function handleSubmit(e){
        e.preventDefault();
        setShowLoading(true);

        if(formData.region_title === ""){
            setShowLoading(false);
            toast.error("All fields are required");
            return;
        }else{
            axios.post(BASE_URL+"/regions",formData,{
                headers: HEADER
            }).then((res)=>{
            setShowLoading(false);

                console.log(res);
                if(res && res.data.status){
                    toast.success("Successfully Created");
                    setShow(false);
                    setFormData({
                        'region_title' : ''
                    });

                }else{
                    toast.error("Something went wrong");
                }
            }).catch(error=>{
                setShowLoading(false);
                if (error.response) {
                    // console.log(error.response.data.message);
                    toast.error(error.response.data.message);
                } else if (error.request) {
                    console.log('Network error. Please check your connection.');
                toast.error("Network error. Please check your connection.");

                } else {
                    console.log('An error occurred while fetching data.');
                    toast.error("An error occurred while processing the request");
                }
            })
        }

    }

    return (
        <>
            {showLoading === true ? (<div className="spinner-box">
                <div className="spinner"></div>

            </div>)
                :
                null
            }
                   <div>
            <div className="card p-3">
                <div className="card-head">
                    <div className="flex">
                        <div className="w-1/2">
                            <h1 className="text-2xl font-bold">All Region</h1>
                        </div>
                        <div className="w-1/2 flex justify-end">
                            <button onClick={handleShow} className="flex items-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-500 hover:border-blue-700 rounded">
                                <IoMdAdd color='white' fontSize={18} />
                                Create
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <table className='table table-striped table-fixed table-hover'>
                        <thead>
                            <tr>
                                <th>S/No</th>
                                <th>Region Name</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data.length > 0 ? data.map((item,index)=>(
                                    <tr key={item.id} className='text-center'>
                                        <td>{index + 1}</td>
                                        <td>{item.region_title}</td>
                                        {/* <td></td> */}
                                        <td className='flex justify-center'><span onClick={()=>{showAlert(item.id)}} className='mx-auto'><MdDelete fontSize={26} color='red' /></span></td>

                                    </tr>
                                )) : (
                                    <tr className='text-center'>
                                        <td colSpan={3}>No data found</td>
                                    </tr>
                                )
                            }

                        </tbody>
                    </table>
                </div>
            </div>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add New Region</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="">
                        <form onSubmit={handleSubmit}>
                        <Form.Control size="lg" type="text" name='region_title' value={formData.region_title} onChange={handleChange} placeholder="Enter Region Name" className='my-3' />
                        <Button type='submit' variant="outline-primary" size="lg" style={{ width: '100%' }} >Submit</Button>
                        </form>
                       
                    </div>
                </Modal.Body>

            </Modal>
        </div> 
        </>
 
    )
}

export default Region