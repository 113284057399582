import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import Sidebar from './sIdebar/Sidebar'
import Header from './header/Header'
import Footer from './footer/Footer'
import "./master.scss";
import Cookies from 'js-cookie'
import axios from 'axios'
import { BASE_URL } from '../../../Config'
import { Toaster } from 'react-hot-toast'

const MasterLayout = () => {
  const [User, setUser] = useState(false);
  const token = Cookies.get("token");
  const user = Cookies.get("user");
  const navigate = useNavigate();
  const [toggleMenu, setToggleMenu] = useState(false);
  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 1050px)").matches
  )

  function Menu() {
    toggleMenu ? setToggleMenu(false) : setToggleMenu(true)
  }
  function closeMenu() {
    if (matches && toggleMenu) {
      setToggleMenu(false)
    }

  }

  useEffect(() => {
    window
      .matchMedia("(max-width: 1050px)")
      .addEventListener('change', e => setMatches(e.matches));
    if (!matches) {
      setToggleMenu(false);
    }
  }, [matches]);

  useEffect(() => {
    if (token && token.length > 0) {
      try {

        axios.get(BASE_URL + "/profile", {
          headers: {
            'Authorization': `Bearer ${token}`, // Include the Bearer token in the Authorization header
            'Content-Type': 'application/json', // Set the content type as needed
          },
        }).then(function (response) {
          // console.log(response);
          if (response.data.status) {
            if (user == 2) {
              navigate("/home");

            } else if (user == 1) {
              setUser(true);
              return;
            }


          }
          Cookies.remove("token");
          Cookies.remove("user");
          navigate("/");

        }).catch(error => {
          if (error.response) {
            // console.log(error.response.data.message);
            // toast.error(error.response.data.message);
            Cookies.remove("token");
            Cookies.remove("user");

            navigate("/");

          } else if (error.request) {
            console.log('Network error. Please check your connection.');
            // toast.error("Network error. Please check your connection.");

          } else {
            console.log('An error occurred while fetching data.');
            // toast.error("An error occurred while processing the request");
            Cookies.remove("token");
            Cookies.remove("user");
            navigate("/");

          }

        });

      } catch (error) {

      }
    } else {
      navigate("/");
    }
  }, [])
  return (
    <div className='admin-layout'>
      <div className={matches && toggleMenu ? "sidebar match" : (matches && !toggleMenu ? "sidebar match none" : "sidebar notmatch")}>
        <Sidebar />
      </div>
      <div className={matches && toggleMenu ? "main-cont match" : (matches && !toggleMenu ? "main-cont match" : "main-cont notmatch")} >
        <div className="header">
          <Header toogle={toggleMenu} match={matches} press={Menu} />
        </div>
        <div className={matches ? "admin-content ng" :'admin-content'}>

          <div className={matches ? "conta" : "conta ng"}>
            <Outlet />
          </div>
          {/* <div className="footer">
            <Footer />
          </div> */}
        </div>

      </div>
      {matches && toggleMenu ? (<div onClick={closeMenu} className="blur">

</div>) : null}
      <Toaster
        position="top-right"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: '',
          duration: 2000,
          // Default options for specific types
          success: {
            duration: 3000,
            theme: {
              primary: 'green',
              secondary: 'white',
            },
            style: {
              color: 'white',
              background: 'green'
            }
          },
          error: {
            duration: 2000,
            theme: {
              primary: 'white',
              secondary: 'red'
            },
            style: {
              color: 'white',
              background: 'red'
            }
          }
        }}
      />
    </div>
  )
}

export default MasterLayout