import axios from 'axios'
import Cookies from 'js-cookie'
import React, { useState } from 'react'
import { AiOutlineCloseCircle, AiOutlineMenuUnfold } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import { BASE_URL, HEADER } from '../../../../Config'
import toast from 'react-hot-toast'

const Header = ({ toogle, press, match }) => {
  const navigate = useNavigate();
  const [current, setCurrent] = useState([]);
  function logout(){
    axios.post(BASE_URL+'/logout',{},{headers:HEADER}).then((res)=>{
      // console.log(res);
      Cookies.remove("token");
      Cookies.remove("user");
      navigate('/login');
      toast.success("Logged out successfully");
    }).catch((err)=>{
      // console.log(err);
      toast.err("Couldn't log out, please try again later");
    });
  

  }


  return (
    <>
      <div className='w-full'>
        <div className="flex justify-between w-full">
          <div className="">
          {
            match ? (<div className="admin-menu" onClick={press}>
              {toogle ? (<AiOutlineCloseCircle fontSize={26} color='black' />) : (<AiOutlineMenuUnfold fontSize={26} color='black' />)}

            </div>) : null
          }
          </div>
   
          {/* <div style={{ marginRight: 24 }}>
          <img src={require("../../../../assets/images/user.png")} style={{height:40, width:40, borderRadius:'50%'}} alt="" />
          
          </div> */}
          <div className="dropdown mx-3">
            <img className="user-avatar" src={require("../../../../assets/images/user.png")} alt="User Avatar"/>
            {/* <span>John Doe</span> */}
            <div className="dropdown-content">
                <a onClick={logout} className="dropdown-item">Logout</a>
            </div>
        </div>
        </div>

      </div>


    </>
  )
}

export default Header