import React, { useState } from 'react';

function MultiSelectDropdown({ options, selectedValues, onSelect }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    onSelect(option);
  };

  return (
    <div className="multi-select-dropdown">
      <div className={`dropdown form-control ${isOpen ? 'open' : ''}`} onClick={toggleDropdown}>
        {selectedValues.length > 0 ? selectedValues.map((item) => item.genre_title).join(', ') : 'Select options'}
      </div>
      {isOpen && (
        <ul className="dropdown-options w-100" style={{backgroundColor:'whitesmoke'}}>
          {options.map((option) => (
            <li key={option.id} className='jk'  onClick={() => handleOptionClick(option)}>
              <input
                type="checkbox"
                className='mr-3'
                checked={selectedValues.some((item) => item.id === option.id)}
                readOnly
              />
              {option.genre_title}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default MultiSelectDropdown;