import React, { useEffect, useState } from 'react'
import { Table, Button, Card, Modal, Form  } from 'react-bootstrap';
import {IoMdAdd} from 'react-icons/io'
import { BASE_URL, HEADER } from '../../../Config';
import Cookies from 'js-cookie';
import axios from 'axios';
import toast from 'react-hot-toast';
import { MdDelete } from 'react-icons/md';
import Swal from 'sweetalert2';

const Types = () => {
    const [types, setTypes] = useState([]);
    const [showLoading, setShowLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [formData, setFormData] = useState({
      type_title : ''
    });
  
  
    const token = Cookies.get('token');
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    function showAlert(e){
        Swal.fire({
        title: 'Are you sure you want to delete it?',
        showDenyButton: true,
        confirmButtonText: 'Yes',
        denyButtonText: `No`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          axios.get(BASE_URL+"/delete_types/"+e,{headers:HEADER}).then((res)=>{
            // console.log(res);
            toast.success("Deleted successfully");
            getAllGenre();
          }).catch(error=>{
            // console.log(error);
            toast.error("Unable to delete, please try again later");
          });
          // Swal.fire('Saved!', '', 'success')
        } else if (result.isDenied) {
          // Swal.fire('Changes are not saved', '', 'info')
        }
      })
       }

    function getAllGenre(){
        setShowLoading(true);
        try {
    
          axios.get(BASE_URL+'/types',{
            headers: {
              'Authorization': `Bearer ${token}`, // Include the Bearer token in the Authorization header
              'Content-Type': 'application/json', // Set the content type as needed
          },
          }).then((res)=>{
            console.log(res);
            setShowLoading(false);
            if(res && res.data.status){
              setTypes(res.data.data);
    
            }
          })
        } catch (error) {
          setShowLoading(false);
          if (error.response) {
              // console.log(error.response.data.message);
              toast.error(error.response.data.message);
          } else if (error.request) {
              console.log('Network error. Please check your connection.');
          toast.error("Network error. Please check your connection.");
    
          } else {
              console.log('An error occurred while fetching data.');
              toast.error("An error occurred while processing the request");
          }
        }
      }
      useEffect(() => {
          getAllGenre();
      }, []);
    const  hangeChange = (e) =>{
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
      }
    
      const handleSubmit = (e) =>{
        e.preventDefault();
        setShowLoading(true);
        if(formData.type_title === ""){
          setShowLoading(false);
          toast.error("Please enter type's name");
          return;
        }else{
          axios.post(BASE_URL+'/types',formData,{
            headers: {
              'Authorization': `Bearer ${token}`, // Include the Bearer token in the Authorization header
              'Content-Type': 'application/json', // Set the content type as needed
          },
          }).then((data)=>{
            setShowLoading(false);
            if (data && data.data.status) {
               toast.success(data.data.message);
               setShow(false);
               getAllGenre();
               setFormData({
                type_title:''
               })
    
            } else {
                toast.error(data.data.message);
            }
          }).catch(error =>{
            setShowLoading(false);
                        if (error.response) {
                            // console.log(error.response.data.message);
                            toast.error(error.response.data.message);
                        } else if (error.request) {
                            console.log('Network error. Please check your connection.');
                        toast.error("Network error. Please check your connection.");
        
                        } else {
                            console.log('An error occurred while fetching data.');
                            toast.error("An error occurred while processing the request");
                        }
          })
        }
      }
    return (
        <>
             {showLoading === true ? (<div className="spinner-box" style={{zIndex:100}}>
                <div className="spinner"></div>

            </div>)
                :
                null
            }
            <div className="card p-3">
                <div className="card-head">
                    <div className="flex">
                        <div className="w-1/2">
                            <h1 className="text-2xl font-bold">All Types</h1>
                        </div>
                        <div className="w-1/2 flex justify-end">
                            <button onClick={handleShow} className="flex items-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-500 hover:border-blue-700 rounded">
                                <IoMdAdd color='white' fontSize={18} />
                                Create
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <table className='table table-striped table-fixed table-hover'>
                        <thead>
                            <tr>
                                <th>S/No</th>
                                <th>Types</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                types.length > 0 ? 
                                  
                                   
                                    types.map((item,index) =>(
                                        <tr className='text-center' key={item.id}>
                                        <td>{index+1}</td>
                                        <td>{item.type_title}</td>
                                        <td className='flex justify-center'><span onClick={()=>{showAlert(item.id)}} className='mx-auto'><MdDelete fontSize={26} color='red' /></span></td>
                                    </tr>
                                    ))
                                   
                                : (
                                    <tr className='text-center'>
                                        <td colSpan={3}>No data found</td>
                                    </tr>
                                )
                            }

                        </tbody>
                    </table>
                </div>
            </div>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add New Type</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="">
                        <form onSubmit={handleSubmit}>
                        <Form.Control size="lg" type="text" name='type_title' value={formData.type_title} onChange={hangeChange} placeholder="Enter Type " className='my-3' />
                        <Button type='submit' variant="outline-primary" size="lg" style={{ width: '100%' }} >Submit</Button>
                        </form>
                        
                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default Types