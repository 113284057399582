import React from 'react'
import {FiType, FiUsers} from 'react-icons/fi'
import {SiMicrogenetics} from 'react-icons/si'
import {LuBookOpen} from 'react-icons/lu'
import {MdAdminPanelSettings} from 'react-icons/md'
import { Link } from 'react-router-dom'
import { BiCurrentLocation, BiSolidCloudUpload } from 'react-icons/bi'

const Sidebar = () => {
  return (
    <div>
      <div className="side-logo">
        <img src={require("../../../../assets/images/logo.png")} alt="logo" />
        <h3>Verified Network</h3>
      </div>
      <p className='admin-head-text'>Administration Dasboard</p>
      <div className="hr"></div>
      <div className="admin-sidebar-items">
        <div className="admin-sidebar-item">
          <Link to={'dashboard'}>
          <div className="admin-sidebar-item-icon">
            <SiMicrogenetics/>
          </div>
          <div className="admin-sidebar-item-text">
            <p>Genres</p>
          </div>
          </Link>
        </div>
        {/* <div className="admin-sidebar-item">
          <Link to={'types'}>
          <div className="admin-sidebar-item-icon">
            <FiType/>
          </div>
          <div className="admin-sidebar-item-text">
            <p>Type</p>
          </div>
          </Link>
      
        </div> */}
        <div className="admin-sidebar-item">
          <Link to={'region'}>
          <div className="admin-sidebar-item-icon">
            <BiCurrentLocation/>
          </div>
          <div className="admin-sidebar-item-text">
            <p>Region</p>
          </div>
          </Link>
      
        </div>
        <div className="admin-sidebar-item">
          <Link to={'publications'}>
          <div className="admin-sidebar-item-icon">
            <LuBookOpen/>
          </div>
          <div className="admin-sidebar-item-text">
            <p>Publications</p>
          </div>
          </Link>
        </div>
        <div className="admin-sidebar-item">
          <Link to={'users'}>
          <div className="admin-sidebar-item-icon">
            <FiUsers/>
          </div>
          <div className="admin-sidebar-item-text">
            <p>Users</p>
          </div>
          </Link>
        </div>
        <div className="admin-sidebar-item">
          <Link to={'administrators'}>
          <div className="admin-sidebar-item-icon">
            <MdAdminPanelSettings/>
          </div>
          <div className="admin-sidebar-item-text">
            <p>Admininistrators</p>
          </div>
          </Link>
        </div>
        <div className="admin-sidebar-item">
          <Link to={'upload-pr'}>
          <div className="admin-sidebar-item-icon">
            <BiSolidCloudUpload/>
          </div>
          <div className="admin-sidebar-item-text">
            <p>Upload PR Question</p>
          </div>
          </Link>
        </div>
       
      </div>
    </div>
  )
}

export default Sidebar