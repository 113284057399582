import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { AiFillEye, AiOutlineDelete } from 'react-icons/ai'
import { FiEdit } from 'react-icons/fi'
import {  Button, Modal, Form  } from 'react-bootstrap';
import { BASE_URL, HEADER } from '../../../Config'
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';

const Administrators = () => {
  const [showLoading, setShowLoading] = useState(false);
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
    // const [showLoading, setShowLoading] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [vPass,setVPass] = useState('');
    const [edit, setEdit] = useState(false);
    const [formData, setFormData] = useState(
      {
          full_name: '',
          email: '',
          password:'',
          phone_no:'',
          user_type:1
      }
  );
  function getAllAdmin(){
    setShowLoading(true);
    axios.get(BASE_URL+'/getAllAdmin',{headers: HEADER}).then((res)=>{
      setShowLoading(false);
      if(res){
        console.log(res.data.data);
        setData(res.data.data);
      }
    }).catch(error=>{
      setShowLoading(false);

      console.log(error);
    })
  }

  function handleChange(e){
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
}

function handleChange2(e){
  const { name, value } = e.target;
  setVPass(e.target.value);
}
function handleSubmit(e){
  e.preventDefault();
  setShowLoading(true);
  if(formData.full_name === ""){
    setShowLoading(false);
    toast.error("Please enter full name");
    return;
  }else if(formData.email === ""){
    setShowLoading(false);
    toast.error("Please enter email address");
    return;
  }else if(formData.password === ""){
    setShowLoading(false);
    toast.error("Please enter password");
    return;
  }else if(formData.password != vPass){
    setShowLoading(false);
    toast.error("Password must be the same");
    return;
  }else if(formData.phone_no === ""){
    setShowLoading(false);
    toast.error("Please enter phone no");
    return;
  }else{
    axios.post(BASE_URL+"/register",formData,{headers:HEADER}).then((res)=>{
      setShowLoading(false);
      if(res.data.status){
        toast.success("Successfully Created");

        getAllAdmin();
        setShow(false);
       setFormData(
          {
              full_name: '',
              email: '',
              password:'',
              phone_no:'',
              user_type:1
          }
      );

      }else{
        toast.error(res.data.message);
      }
      console.log(res.data);
    }).catch((err)=>{
      setShowLoading(false);
      
      console.log(err);
    })
  }
}


function showAlert(e){
  Swal.fire({
  title: 'Are you sure you want to delete it?',
  showDenyButton: true,
  confirmButtonText: 'Yes',
  denyButtonText: `No`,
}).then((result) => {
  /* Read more about isConfirmed, isDenied below */
  if (result.isConfirmed) {
    axios.get(BASE_URL+"/delete_user/"+e,{headers:HEADER}).then((res)=>{
      // console.log(res);
      toast.success("Deleted successfully");
      getAllAdmin();
    }).catch(error=>{
      // console.log(error);
      toast.error("Unable to delete, please try again later");
    });
    // Swal.fire('Saved!', '', 'success')
  } else if (result.isDenied) {
    // Swal.fire('Changes are not saved', '', 'info')
  }
})
 }

  useEffect(()=>{
    getAllAdmin();
  },[])
  return (
    <>
      {showLoading === true ? (<div className="spinner-box">
                <div className="spinner"></div>

            </div>)
                :
                null
            }
    <div className="container-flu p-3">
      <div className="card">
        <div className="card-head flex justify-between p-3">
          <h1>Administrators</h1>
          <button onClick={handleShow} className='btn btn-outline-primary btn-sm '>Add New</button>
        </div>
        <div className="card-body">
          <div className="table-responsive-lg">
            <table className="table table-responsive-lg table-striped table-hover">
              <thead>
                <tr className='text-center'>
                  <td>S/No</td>
                  <td style={{minWidth: 150}}>Full Name</td>
                  <td style={{minWidth: 150}}>Email Address</td>
                  <td style={{minWidth: 150}}>Phone No</td>
                  {/* <td style={{minWidth: 70}}>Status</td> */}
                  {/* <td style={{minWidth: 70}}>Action</td> */}
                </tr>
              </thead>
              <tbody>
                {data.length > 0 ? data.map((item,index)=>(
                   <tr key={item.id} className="text-center">
                   <td>{index + 1}</td>
                   <td>{item.full_name}</td>
                   <td>{item.email}</td>
                   <td>{item.phone_no}</td>
                   {/* <td>{item.status == 1 ? "Active" : 'Deactive'}</td> */}
                   <td>
                   <div className="flex justify-center">
              {/* <span  style={{marginRight:20, padding:5}}><FiEdit fontSize={24} color='light-green'/></span> */}
              <span onClick={()=>{showAlert(item.id)}}  style={{padding:5}}><AiOutlineDelete fontSize={24} color='red'/></span>
 
                 </div>
                   </td>
                 </tr>
                )) : (<tr className='text-center'><td colSpan={4}>No Data Found</td></tr>)}
               
              </tbody>
            </table>
          </div>
        </div>
        <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add New Admin</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="">
                        <form onSubmit={handleSubmit}>
                        <Form.Control size="lg" type="text" name='full_name' value={formData.full_name} onChange={handleChange} placeholder="Enter Full Name" className='my-3' />
                        <Form.Control size="lg" type="email" name='email' value={formData.email} onChange={handleChange} placeholder="Enter Email Address" className='my-3' />
                        <Form.Control size="lg" type="password" name='password' value={formData.password} onChange={handleChange} placeholder="Enter Password" className='my-3' />
                        <Form.Control size="lg" type="password" name='verify_password' value={vPass} onChange={handleChange2} placeholder="Verify Password" className='my-3' />
                        <Form.Control size="lg" type="text" name='phone_no' value={formData.phone_no} onChange={handleChange} placeholder="Enter Phone No." className='my-3' />

                        <Button type='submit' variant="outline-primary" size="lg" style={{ width: '100%' }} >Create</Button>
                        </form>
                       
                    </div>
                </Modal.Body>

            </Modal>

      </div>
    </div>
    </>
  )
}

export default Administrators