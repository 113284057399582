import React from 'react';

function CurrencyFormatter({ amount }) {
  // Format the number as currency using the user's locale
  const formattedAmount = amount.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD', // Replace with the appropriate currency code (e.g., 'EUR' for Euro)
    minimumFractionDigits: 2, // Minimum number of decimal places
  });

  return formattedAmount;
}

export default CurrencyFormatter;