import React, { useEffect, useState } from 'react'
import "./auth.scss";
import { Navigate, useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import { BASE_URL } from '../../Config';
import Cookies from 'js-cookie';

const Forgot = () => {
    const [vie, setView] = useState('Forgot');
    const [showLoading, setShowLoqading] = useState(false);
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        setShowLoqading(true);
        // console.log("emil address =>", email);
        axios.post(BASE_URL+'/sendEmail',{email: email}, {
            headers:{
                "Accept": 'application/json',
                "Content-Type" : 'application/json'
            }
        }).then(response => {
            console.log(response);
            const info = response.data;
            setShowLoqading(false);
            if(response && info.code === 201){

                toast.success(info.message);
                setView("otp");


            }else{
                toast.error("Check your email address");
            }
        }).catch(err => {
            setShowLoqading(false);

            // console.log(err);
            toast.error("Something went wrong");

        });
    }

    const handleOtp = (e) => {
        e.preventDefault();
        setShowLoqading(true);
        // console.log("emil address =>", email);
        axios.post(BASE_URL+'/validateOtp',{email: email,otp: otp}, {
            headers:{
                "Accept": 'application/json',
                "Content-Type" : 'application/json'
            }
        }).then(response => {
            console.log(response);
            const info = response.data;
            setShowLoqading(false);
            if(response && info.code === 201){
                toast.success(info.message);
                localStorage.setItem("hj", email)
                navigate("/new-password");

            }else{
                toast.error("Check your email address");
            }
        }).catch(err => {
            // console.log(err);
            setShowLoqading(false);

            toast.error("Something went wrong");

        });
    }
    return (
        <>
            {showLoading === true ? (<div className="spinner-box">
                <div className="spinner"></div>

            </div>)
                :
                null
            }
            {vie === 'Forgot' ? (

                <div className='container-fluids flex justify-center align-items-center'>
                    <div className="cardi">
                        <div className="logo text-center">
                            <h4 style={{color:'var(--primary-color)'}}>Reset Your Password</h4>
                            <p style={{color:'var(--white-color)'}}>Please enter your registered email address.</p>
                        </div>
                        <div className="card-body">
                            <form onSubmit={handleSubmit}>
                                <div className="form-group mb-3">
                                    <label htmlFor="email">Email Address</label>
                                    <input type="email" placeholder='Enter your Email Address' className='w-100' name="email" value={email} onChange={(text) => { setEmail(text.target.value) }} id="email" />
                                </div>

                                <div className="form-group mb-3">
                                    <button type='submit' style={{color:'var(--background-color)'}}>Send reset link</button>
                                </div>
                                <p class={"reset"}>Remember password? <span onClick={()=>{navigate("/login");}}>Login</span></p>
                            </form>
                        </div>
                        <Toaster
                            position="top-right"
                            reverseOrder={false}
                            gutter={8}
                            containerClassName=""
                            containerStyle={{}}
                            toastOptions={{
                                // Define default options
                                className: '',
                                duration: 2000,
                                // Default options for specific types
                                success: {
                                    duration: 3000,
                                    theme: {
                                        primary: 'green',
                                        secondary: 'white',
                                    },
                                    style: {
                                        color: 'white',
                                        background: 'green'
                                    }
                                },
                                error: {
                                    duration: 2000,
                                    theme: {
                                        primary: 'white',
                                        secondary: 'red'
                                    },
                                    style: {
                                        color: 'white',
                                        background: 'red'
                                    }
                                }
                            }}
                        />
                    </div>
                </div>
            ) : (
                <div className='container-fluids flex justify-center align-items-center'>
                <div className="cardi">
                    <div className="logo text-center">
                        <h4 style={{color:'var(--primary-color)'}}>Verification Code</h4>
                        <p style={{color:'var(--white-color)'}}>Please enter verification code sent to your email.</p>
                    </div>
                    <div className="card-body">
                        <form onSubmit={handleOtp}>
                            <div className="form-group mb-3">
                                <label htmlFor="email">Email Address</label>
                                <input type="email" readOnly disabled placeholder='Enter your Email Address' className='w-100' name="email" value={email} onChange={(text) => { setEmail(text.target.value) }} id="email" />
                            </div>
                            <div className="form-group mb-3">
                                <label htmlFor="email">Verification code</label>
                                <input type="text" required  placeholder='Enter verification code sent to your email' className='w-100' name="otp" value={otp} onChange={(text) => { setOtp(text.target.value) }} id="email" />
                            </div>

                            <div className="form-group mb-3">
                                <button type='submit' style={{color:'var(--background-color)'}}>Send reset link</button>
                            </div>
                            {/*<p class={"reset"}>Don't have an account? <span onClick={()=>{window.location.href = "/create-account";}}>Sign up</span></p>*/}
                        </form>
                    </div>
                    <Toaster
                        position="top-right"
                        reverseOrder={false}
                        gutter={8}
                        containerClassName=""
                        containerStyle={{}}
                        toastOptions={{
                            // Define default options
                            className: '',
                            duration: 2000,
                            // Default options for specific types
                            success: {
                                duration: 3000,
                                theme: {
                                    primary: 'green',
                                    secondary: 'white',
                                },
                                style: {
                                    color: 'white',
                                    background: 'green'
                                }
                            },
                            error: {
                                duration: 2000,
                                theme: {
                                    primary: 'white',
                                    secondary: 'red'
                                },
                                style: {
                                    color: 'white',
                                    background: 'red'
                                }
                            }
                        }}
                    />
                </div>
            </div>
            ) }
        </>
    )
}

export default Forgot

export const NewPassword = () => {

    const [formData, setFormData] = useState(
        {
            email: '',
            password: ''
        }
    )

    const [showLoading, setShowLoading] = useState(false);
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const [cpassword, setCPassword] = useState("");
    useEffect(()=>{
        const email = localStorage.getItem("hj");
        if(!email){
            navigate('/login');
        }
    },[])
    const handleSubmit = (e) => {
        e.preventDefault();
        setShowLoading(true);
        if (formData.password === "") {
            toast.error("Please enter your password");
            setShowLoading(false);
        }else if(formData.password != cpassword){
            toast.error("Password must be the same");
            setShowLoading(false);
        } else {           
        const email = localStorage.getItem("hj");
            
            axios.post(BASE_URL + "/changePassword", {email:email, password: formData.password}, {
                headers:{
                    "Accept": 'application/json',
                    "Content-Type" : 'application/json'
                }
            }).then((data) => {
                setShowLoading(false);
                if (data && data.data.status) {
                    toast.success(data.data.message)
                    navigate("/login")

                } else {
                    toast.error(data.data.message);
                }
            }).catch(error => {
                setShowLoading(false);
                if (error.response) {
                    // console.log(error.response.data.message);
                    toast.error(error.response.data.message);
                } else if (error.request) {
                    // console.log('Network error. Please check your connection.');
                    toast.error("Network error. Please check your connection.");

                } else {
                    // console.log('An error occurred while fetching data.');
                    toast.error("An error occurred while processing the request");
                }

            })

        }
    }

    return (
        <>
            {showLoading === true ? (<div className="spinner-box">
                <div className="spinner"></div>

            </div>)
                :
                null
            }
            <div className='container-fluids flex justify-center align-items-center'>
                <div className="cardi">
                    <div className="logo text-center">
                        <img src={require("../../assets/images/logo.png")} width={200} height={'auto'} alt="logo" />
                    </div>
                    <div className="card-body">
                        <form onSubmit={handleSubmit}>
                            <div className="form-group mb-3">
                                <label htmlFor="password">Password</label>
                                <input type="password" placeholder='Enter your password' className='w-100' name="password" value={formData.password} onChange={handleChange} id="email" />
                            </div>
                            <div className="form-group mb-3">
                                <label htmlFor="password">Confirm Password</label>
                            <input type="password" placeholder='Enter confirm password' className='w-100' name="c_password" value={cpassword} onChange={(e)=>{setCPassword(e.target.value)}} id="email" />
                            </div>
                        
                            <div className="form-group mb-3">
                                <button type='submit'>Continue</button>

                            </div>
                            {/*<p class={"reset"}>Don't have an account? <span onClick={()=>{window.location.href = "/create-account";}}>Sign up</span></p>*/}
                        </form>
                    </div>
                    <Toaster
                        position="top-right"
                        reverseOrder={false}
                        gutter={8}
                        containerClassName=""
                        containerStyle={{}}
                        toastOptions={{
                            // Define default options
                            className: '',
                            duration: 2000,
                            // Default options for specific types
                            success: {
                                duration: 3000,
                                theme: {
                                    primary: 'green',
                                    secondary: 'white',
                                },
                                style: {
                                    color: 'white',
                                    background: 'green'
                                }
                            },
                            error: {
                                duration: 2000,
                                theme: {
                                    primary: 'white',
                                    secondary: 'red'
                                },
                                style: {
                                    color: 'white',
                                    background: 'red'
                                }
                            }
                        }}
                    />
                </div>
            </div>
        </>

    )
}



