// import { upload } from '@testing-library/user-event/dist/upload';
import axios from 'axios';
import React, { useState } from 'react'
import toast from 'react-hot-toast';
import { BASE_URL, HEADER, TOKEN } from '../../Config';

const UploadPR = () => {
    const [doc, setDoc] = useState('');
    const [showLoading, setShowLoading] = useState(false);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setDoc(file);

    }

   async function UploadFile(e){
    e.preventDefault();
    setShowLoading(true);
    if(doc === ""){

    setShowLoading(false);
        toast.error("Please choose a file to upload");
        return;
    }else{
        const formData = new FormData();
      formData.append('file', doc);

      try {
        await axios.post(BASE_URL+'/upload_pr', formData, {
            headers:{
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${TOKEN}`, 
            }
        }).then((res)=>{
    setShowLoading(false);

            // console.log(res.data);
            toast.success("Uploaded successfully");
        }).catch(e=>{
            // console.log(e);
    setShowLoading(false);
    toast.error("Unable to upload");
        });
      } catch (error) {
        // Handle errors here
        // console.error('Error uploading file:', error);
        setShowLoading(false);
        toast.error("Unable to upload");

      }
    }

   }
  return (
    <>
       {showLoading === true ? (<div className="spinner-box">
                <div className="spinner"></div>

            </div>)
                :
                null
            }
              <div className="container">
        <div className="card p-3">
            <form onSubmit={UploadFile}>
                <div className="form-group mb-3">
                <label htmlFor="" style={{color:'black'}}>Upload PR Question</label>
            <input type="file" className='form-control' required  onChange={handleImageChange}  />
                </div>
                <div className="form-group ">
                    <button type='submit' className='btn btn-outline-primary p-2 mx-auto w-100'>Upload</button>
                </div>
            </form>
        
        </div>
    </div>
    </>
  
  )
}

export default UploadPR