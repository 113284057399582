import React, { useEffect, useState } from 'react'
import MultiSelectDropdown from '../../../components/MultiSelectDropdown';
import axios from 'axios';
import { BASE_URL, HEADER, TOKEN } from '../../../Config';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const AddPublication = () => {

    const [showLoading, setShowLoading] = useState(false);

    const [selectedValues, setSelectedValues] = useState([]);
    const [genreData, setGenresData] = useState([]);
    const [typeData, setTypeData] = useState([]);
    const [regionData, setRegionData] = useState([]);
    const [previewCover, setPreviewCover] = useState('');
    const [previewSample, setPreviewSample] = useState('');
    const [selectedCover, setSelectedCover] = useState('');
    const [selectedSample, setSelectedSample] = useState('');
    const [formData, setFormData] = useState({
        publication_name: '',
        publication_link: '',
        tat: '',
        do_follow:  '',
        publication_disclamer: '',
        cover_image:'',
        preview_image:'',
        image:'',
        sponsored:'',
        da:'',
        indexed:'',
        type_id:'',
        region_id:'',
        price: 0,
        genre_id: ''


    })
    const navigate = useNavigate();

    const handleImageChange = (e) => {
        const file = e.target.files[0];


        if (file) {
            const reader = new FileReader();

            reader.onload = (e) => {
                setPreviewCover(e.target.result);
            };
            setSelectedCover(file);

            reader.readAsDataURL(file);
        } else {
            setPreviewCover(null);
        }
    };


    const handleSampleChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onload = (e) => {
                setPreviewSample(e.target.result);
           setSelectedSample(file);
            };

            reader.readAsDataURL(file);
        } else {
            setPreviewSample(null);
        }
    };

    const handleSelect = (option) => {
        if (selectedValues.some((item) => item.id === option.id)) {
            setSelectedValues(selectedValues.filter((item) => item.id !== option.id));
        // console.log(selectedValues);

        } else {
            setSelectedValues([...selectedValues, option]);
        // console.log(selectedValues);

        }
    };

    function getGenre() {
        axios.get(BASE_URL + '/genres', { headers: HEADER }).then((res) => {
            // console.log(res.data);
            setGenresData(res.data);
        }).catch(error => {
            console.log(error);
        });
    }
    function getAllRegion() {
        axios.get(BASE_URL + '/regions', { headers: HEADER }).then((res) => {
            // console.log(res.data);
            setRegionData(res.data);
        }).catch(error => {
            console.log(error);
        });
    }

    function getType() {
        axios.get(BASE_URL + '/types', { headers: HEADER }).then((res) => {
            // console.log(res.data.data);
            setTypeData(res.data.data);
        }).catch(error => {
            console.log(error);
        });
    }

    useEffect(() => {
        getGenre();
        getAllRegion();
        getType();
    }, []);

    const handleFormChange = (e) =>{
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    }

    function handleSubmit(e){
        e.preventDefault();
        setShowLoading(true);
        setFormData({genre_id: selectedValues});
      
        // console.log("new",selectedValues);

        if(selectedSample != "" && selectedCover != ""){
        const formD = new FormData();

            formD.append('image',selectedCover);
            formD.append('sample',selectedSample);
            // console.log(formD);
            axios.post(BASE_URL+"/upload_images",formD,{
                headers:{
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${TOKEN}`, 
                }
            }).then((res)=>{
                // console.log(res.data.names);
                const info = res.data.names;
                setFormData({
                    cover_image: info[0],
                    preview_image: info[1]
                });
                submitF(info[0], info[1]);
            }).catch((err)=>{
                console.log(err);
            })

            
        }else{
        const formD = new FormData();

            formD.append('image',selectedCover);
            axios.post(BASE_URL+"/upload_images",formD,{
                headers:{
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${TOKEN}`, 
                }
            }).then((res)=>{
                // console.log(res.data.names[0]);
                const info = res.data.names[0];

                setFormData({
                    cover_image: info[0],
                });
                submitF(info,'');
            }).catch((err)=>{
                console.log(err);
            })



        }
       
  
    }

    function submitF(cover,preview){
        const param = {
            publication_name: formData.publication_name,
            publication_link: formData.publication_link,
            tat: formData.tat,
            do_follow:  formData.do_follow,
            publication_disclamer: '',
            cover_image: cover,
            preview_image:preview,
            image:formData.image,
            sponsored:formData.sponsored,
            da:formData.da,
            indexed:formData.indexed,
            type_id: formData.type_id,
            region_id: formData.region_id,
            price: formData.price,
            genre_id: JSON.stringify(selectedValues)
        }
       axios.post(BASE_URL+"/add_publication",param,{
        headers:HEADER
       }).then((res)=>{
        setShowLoading(false);
        
        // console.log(res);
        toast.success("Publication created successfully");
        navigate("/admin/publications");
       }).catch((err)=>{
        setShowLoading(false);

        console.log(err);

       });
    }


    return (
        <>
               {showLoading === true ? (<div className="spinner-box">
                <div className="spinner"></div>

            </div>)
                :
                null
            }
            <div className="">
            <div className="card p-3 bg-grey">
                <div className="card-header">
                    <h2 className="text-center text-muted">Add New Publication</h2>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="form-group mb-3">
                        <label htmlFor="publication_name">Publication Name</label>
                        <input type="text" className='form-control' required name='publication_name' value={formData.publication_name} onChange={handleFormChange} placeholder='Enter publication name' />
                    </div>

                    <div className="form-group mb-3">
                        <label htmlFor="publication_name">Publication Website Url</label>
                        <input type="text" className='form-control'  required name='publication_link' value={formData.publication_link} onChange={handleFormChange} placeholder='Enter publication website address' />
                    </div>

                    <div className="form-group mb-3">
                        <label htmlFor="publication_name">Genres</label>
                        <MultiSelectDropdown options={genreData} selectedValues={selectedValues} onSelect={handleSelect} />

                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="publication_name">Publication Logo</label>
                        <input type="file" className='form-control' required accept='image/*' onChange={handleImageChange} />

                    </div>
                    {
                        previewCover && (
                            <div className="row">
                                <div className="col-4"></div>
                                <div className="col-4"></div>
                                <div className="col-4">
                                    <div className="img-preview">
                                        <img src={previewCover} alt="" />
                                    </div>
                                </div>

                            </div>
                        )
                    }
                    <div className="form-group mb-3">
                        <label htmlFor="publication_name">Region</label>
                        <select  required name='region_id' value={formData.region_id} onChange={handleFormChange} className='form-control' id="">
                            <option value="">Choose region</option>
                            {regionData.map((item, index) => (
                                <option value={item.id} key={item.id}>{item.region_title}</option>
                            ))}
                        </select>
                    </div>
                    {/* <div className="form-group mb-3">
                        <label htmlFor="publication_name">Type</label>
                        <select  name='type_id' value={formData.type_id} onChange={handleFormChange} className='form-control' id="">
                            <option value="">Choose one</option>
                            {typeData.map((item, index) => (
                                <option value={item.id}>{item.type_title}</option>
                            ))}

                        </select>
                    </div> */}
                    <div className="form-group mb-3">
                        <label htmlFor="publication_name">Price</label>
                        <input type="number"  required name='price' value={formData.price} onChange={handleFormChange} className='form-control' placeholder='Enter price' />
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="publication_name">Domain Authority(DA)</label>
                        <input type="text" className='form-control' required name='da' value={formData.da} onChange={handleFormChange} placeholder='Enter Domain Authority' />
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="publication_name">TAT</label>
                        <input type="text" className='form-control' required name='tat' value={formData.tat} onChange={handleFormChange} placeholder='Enter Estimated time of deliver' />
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="publication_name">Sponsored</label>
                        <select name="sponsored" required value={formData.sponsored} onChange={handleFormChange} className='form-control' id="">
                            <option value="">Choose one</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                            <option value="discrete">Discrete</option>
                        </select>
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="publication_name">Indexed</label>
                        <select name="indexed"  value={formData.indexed} onChange={handleFormChange} className='form-control' id="">
                            <option value="">Choose one</option>
                            <option value="yes">Yes</option>
                            <option value="maybe">Maybe</option>
                            {/* <option value="discrete">Discrete</option> */}
                        </select>
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="publication_name">Image</label>
                        <select required name='image' value={formData.image} onChange={handleFormChange} className='form-control' id="">
                            <option value="">Choose one</option>
                            <option value="yes">Yes</option>
                            <option value="maybe">Maybe</option>
                            {/* <option value="discrete">Discrete</option> */}
                        </select>
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="publication_name">Do Follow</label>
                        <select required name='do_follow' value={formData.do_follow} onChange={handleFormChange} className='form-control' id="">
                            <option value="">Choose one</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                            <option value="maybe">Maybe</option>
                        </select>
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="publication_name">Sample Image</label>
                        <input type="file" className='form-control' name="" onChange={handleSampleChange} accept='image/*' id="" />
                    </div>
                    {
                        previewSample && (
                            <div className="row">
                                <div className="col-4"></div>
                                <div className="col-4"></div>
                                <div className="col-4">
                                    <div className="img-preview">
                                        <img src={previewSample} alt="" />
                                    </div>
                                </div>

                            </div>
                        )
                    }

                    <div className="form-group mb-3">
                        <button type="submit" className='form-control btn btn-sm btn-outline-primary'>Submit</button>
                    </div>
                </form>
            </div>
            </div>
        
        </>
    )
}

export default AddPublication