
import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
// import { ProductService } from './service/ProductService';
import { Table, Button, Card, Modal, Form  } from 'react-bootstrap';
import {IoMdAdd} from 'react-icons/io'
import axios from 'axios';
import toast from 'react-hot-toast';
// import { BASE_URL } from '../../../Config';
import { BASE_URL, HEADER } from '../../../Config';

import Cookies from 'js-cookie';
import { MdDelete } from 'react-icons/md';
import Swal from 'sweetalert2';



const Dashboard = () => {
  const [genres, setGenres] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
    genre_title : ''
  });


  const token = Cookies.get('token');
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function getAllGenre(){
    setShowLoading(true);
    try {

      axios.get(BASE_URL+'/genres',{
        headers: {
          'Authorization': `Bearer ${token}`, // Include the Bearer token in the Authorization header
          'Content-Type': 'application/json', // Set the content type as needed
      },
      }).then((res)=>{
        console.log(res);
        setShowLoading(false);
        if(res && res.status === 200){
          setGenres(res.data);

        }
      })
    } catch (error) {
      setShowLoading(false);
      if (error.response) {
          // console.log(error.response.data.message);
          toast.error(error.response.data.message);
      } else if (error.request) {
          console.log('Network error. Please check your connection.');
      toast.error("Network error. Please check your connection.");

      } else {
          console.log('An error occurred while fetching data.');
          toast.error("An error occurred while processing the request");
      }
    }
  }

  function showAlert(e){
    Swal.fire({
    title: 'Are you sure you want to delete it?',
    showDenyButton: true,
    confirmButtonText: 'Yes',
    denyButtonText: `No`,
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      axios.get(BASE_URL+"/deleteGenre/"+e,{headers:HEADER}).then((res)=>{
        // console.log(res);
        toast.success("Deleted successfully");
        getAllGenre();
      }).catch(error=>{
        // console.log(error);
        toast.error("Unable to delete, please try again later");
      });
      // Swal.fire('Saved!', '', 'success')
    } else if (result.isDenied) {
      // Swal.fire('Changes are not saved', '', 'info')
    }
  })
   }
  useEffect(() => {
      getAllGenre();
  }, []);
const  hangeChange = (e) =>{
  const { name, value } = e.target;
  setFormData({ ...formData, [name]: value });
  }

  const handleSubmit = (e) =>{
    e.preventDefault();
    setShowLoading(true);
    if(formData.genre_title === ""){
      setShowLoading(false);
      toast.error("Please enter genre name");
      return;
    }else{
      axios.post(BASE_URL+'/genres',formData,{
        headers: {
          'Authorization': `Bearer ${token}`, // Include the Bearer token in the Authorization header
          'Content-Type': 'application/json', // Set the content type as needed
      },
      }).then((data)=>{
        setShowLoading(false);
        if (data && data.data.status) {
           toast.success(data.data.message);
           setShow(false);
           getAllGenre();

           setFormData({
            genre_title:''
           })

        } else {
            toast.error(data.data.message);
        }
      }).catch(error =>{
        setShowLoading(false);
                    if (error.response) {
                        // console.log(error.response.data.message);
                        toast.error(error.response.data.message);
                    } else if (error.request) {
                        console.log('Network error. Please check your connection.');
                    toast.error("Network error. Please check your connection.");
    
                    } else {
                        console.log('An error occurred while fetching data.');
                        toast.error("An error occurred while processing the request");
                    }
      })
    }
  }
  return (
    <>
      {showLoading === true ? (<div className="spinner-box">
                <div className="spinner"></div>

            </div>)
                :
                null
            }
              <div className=''>
      <div className="card p-3">
        <div className="flex">
          <div className="w-1/2">
            <h1 className="text-2xl font-bold">All Genres</h1>
          </div>
          <div className="w-1/2 flex justify-end">
            <button onClick={handleShow} className="flex items-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-500 hover:border-blue-700 rounded">
            <IoMdAdd color='white' fontSize={18} />
              Create
            </button>
          </div>
        </div>
        <hr />
        <div className="table-responsive text-center flex justify-center">
       <table className='table table-striped table-hover table-responsive'>
        <thead className='bg-yellow-600'>
          <tr className='text-center'>
            <th>S/No</th>
            <th style={{minWidth: 150}}>Genre Title</th>
            {/* <th>Status</th> */}
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {genres.map((item,index)=>(
            <tr className='text-center' key={item.id}>
            <td>{index + 1}</td>
            <td>{item.genre_title}</td>
            {/* <td>{item.genre_status == 1 ? 'Active' : 'Deactive'}</td> */}
            <td className='flex justify-center'><span onClick={()=>{showAlert(item.id)}} className='mx-auto'><MdDelete fontSize={26} color='red' /></span></td>
          </tr>
          ))}
          
        </tbody>
       </table>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New Genre</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="">
          <form onSubmit={handleSubmit}>
          <Form.Control size="lg" type="text" name="genre_title" value={formData.genre_title} onChange={hangeChange} placeholder="Enter genre" className='my-3' />
        <Button type='submit' variant="outline-primary" size="lg" style={{width: '100%'}} >Submit</Button>
          </form>
      
        </div>
        </Modal.Body>
       
      </Modal>
    </div>
    </>
  
  )
}

export default Dashboard