import React, { useEffect, useState } from 'react';
import "./publication.scss";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL, HEADER, IMAGE_URL } from '../../../Config';
import CurrencyFormatter from '../../../components/CurrencyFormatter';
import GetLoopItem from '../../../components/GetLoopItem';
import { AiFillEye, AiOutlineDelete } from 'react-icons/ai';
import { FiEdit } from 'react-icons/fi';
import Swal from 'sweetalert2';
import toast from 'react-hot-toast';

const Publications = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [showLoading, setShowLoading] = useState(false);

  function getAllPublication(){
    setShowLoading(true);
    axios.get(BASE_URL+"/getAllPublication",{headers:HEADER}).then((res)=>{
      setShowLoading(false);
      console.log(res.data);
      setData(res.data);
    }).catch((err)=>{
      setShowLoading(false);

      console.log(err);
    })
  }

function showAlert(e){
  Swal.fire({
  title: 'Are you sure you want to delete it?',
  showDenyButton: true,
  confirmButtonText: 'Yes',
  denyButtonText: `No`,
}).then((result) => {
  /* Read more about isConfirmed, isDenied below */
  if (result.isConfirmed) {
    axios.get(BASE_URL+"/delete_publication/"+e,{headers:HEADER}).then((res)=>{
      // console.log(res);
      toast.success("Deleted successfully");
      getAllPublication();
    }).catch(error=>{
      // console.log(error);
      toast.error("Unable to delete, please try again later");
    });
    // Swal.fire('Saved!', '', 'success')
  } else if (result.isDenied) {
    // Swal.fire('Changes are not saved', '', 'info')
  }
})
 }

  useEffect(()=>{
    getAllPublication();
  },[]);

  function loopData(loopp){
    const fg = JSON.parse(loopp);
    return fg;

  }
  return (
    <>
       {showLoading === true ? (<div className="spinner-box">
                <div className="spinner"></div>

            </div>)
                :
                null
            }
       <div className='card p-3'>
      <div className="flex jusify-end w-100">
      <button style={{width:120}} className='btn btn-sm btn-outline-primary' onClick={()=>{navigate('/admin/add-publication')}} >Add New</button>

      </div>
      <div className="table-responsive-lg mt-3 ">
        <table className="table table-striped table-hover table-responsive-lg">
          <thead>
            <tr className='text-center'>
              <th>S/No</th>
              <th style={{minWidth:150}}>Publication</th>
              <th style={{minWidth:150}}>Genres</th>
              <th style={{minWidth:70}}>Price</th>
              <th >DA</th>
              <th>TAT</th>
              <th>Region</th>
              <th>Sponsored</th>
              <th>Indexed</th>
              <th>Image</th>
              <th>Do Follow</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {data.length === 0 ? (
              <tr className='text-center'>
                <td colSpan={12}>No data found</td>
              </tr>
            ) : data.map((item, index) =>(
              <tr className='text-center align-middle' key={index}>
              <td>{index+1}</td>
              <td>
                <div className="flex align-items-center align-middle">
                  <div className="p_image">
                    <img src={IMAGE_URL+'/'+item.cover_image} alt="" />
                  </div>
                  <p>{item.publication_name}</p>
                </div>
              </td>
              <td>
                {loopData(item.genre_id).map((h,index)=>
                (
                  <span>{h.genre_title}  {index !== loopData(item.genre_id).length - 1 && ', '}</span>
                ))}
              </td>
              <td>
                <CurrencyFormatter amount={item.price}/>
              </td>
              <td className='capitalize'>{item.da}</td>
              <td className='uppercase'>{item.tat}</td>
              <td className='capitalize'>{item.region_title}</td>
              <td className='capitalize'>{item.sponsored}</td>
              <td className='capitalize'>{item.indexed}</td>
              <td className='capitalize'>{item.image}</td>
              <td className='capitalize'>{item.do_follow}</td>
              <td className=' '>
                <div className="flex">
              <span onClick={()=>{navigate("/admin/view-publication/"+item.id)}} style={{marginRight:20, padding:5}}><AiFillEye fontSize={24} color='light-green'/></span><span onClick={()=>{navigate("/admin/edit-publication/"+item.id)}} style={{marginRight:20, padding:5}}><FiEdit fontSize={24} color='light-green'/></span><span onClick={()=>{showAlert(item.id)}} style={{padding:5}}><AiOutlineDelete fontSize={24} color='red'/></span>

                </div>
              </td>
            </tr>
            ))
            
            }
       
          </tbody>
        </table>
      </div>
    </div>
    </>
 
  )
}

export default Publications;