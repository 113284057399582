import React, { useEffect, useState } from 'react'
import "./auth.scss";
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import { BASE_URL } from '../../Config';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const CreateAccount = () => {
    const [formData, setFormData] = useState({
        full_name: '',
        email: '',
        password: '',
        verify_password: '',
        phone_no: '',
        user_type: 2,
    })
    const [showLoading, setShowLoading] = useState(false);
    const navigate = useNavigate();
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    useEffect(() => {
        const token = Cookies.get('token');
        const uid = Cookies.get("user");
        if (token && token.length > 0) {
            if(uid === 1){
                navigate("/admin");
            }else{
                navigate("/home");

            }

        }
        return;
    },[]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setShowLoading(true);
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (formData.full_name === "") {
            setShowLoading(false);
            toast.error("Please enter your full name");
            return;
        } else if (formData.email === "") {
            setShowLoading(false);
            toast.error("Please enter your email address");
            return;
        } else if (!emailRegex.test(formData.email)) {
            setShowLoading(false);
            toast.error("Please enter valid email address");
            return;
        } else if (formData.password === "") {
            setShowLoading(false);
            toast.error("Please enter your password");
            return;
        } else if (formData.verify_password !== formData.password) {
            setShowLoading(false);
            toast.error("Passwords not match");
            return;
        } else if (formData.phone_no === "") {
            setShowLoading(false);
            toast.error("Please enter your phone number");
            return;
        } else {
         
                axios.post(BASE_URL + '/register', formData, {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                    }
                }).then((response) => {
                    // console.log(response);
                    setShowLoading(false);
                    if (response && response.data.status) {
                        const info = response.data.data;
                        toast.success(response.data.message);
                        Cookies.set("token", response.data.token);
                        Cookies.set("user", info.user_type);
                        // window.location.href = "/home";
                        navigate('/home');

                    } else {
                        toast.error(response.data.message);
                    }
                }).catch(error => {
                    setShowLoading(false);
                    if (error.response) {
                        // console.log(error.response.data.message);
                        toast.error(error.response.data.message);
                    } else if (error.request) {
                        // console.log('Network error. Please check your connection.');
                    toast.error("Network error. Please check your connection.");
    
                    } else {
                        // console.log('An error occurred while fetching data.');
                        toast.error("An error occurred while processing the request");
                    }
                 
                })
          

        }
    }

    return (
        <>
            {showLoading === true ? (<div className="spinner-box">
                <div className="spinner"></div>

            </div>)
                :
                null
            }

            <div className='container-fluids flex justify-center align-items-center py-3'>
                <div className="cardi">
                    <div className="logo text-center">
                        <img src={require("../../assets/images/logo.png")} width={200} height={'auto'} alt="logo" />
                    </div>
                    <div className="card-body">
                        <form onSubmit={handleSubmit}>
                            <div className="form-group mb-3">
                                <label htmlFor="name">Full Name</label>
                                <input type="text" placeholder='Enter your fullname' className='w-100' name="full_name" value={formData.full_name} onChange={handleChange} id="name" />
                            </div>
                            <div className="form-group mb-3">
                                <label htmlFor="email">Email Address</label>
                                <input type="email" placeholder='Enter your Email Address' className='w-100' name="email" value={formData.email} onChange={handleChange} id="email" />
                            </div>

                            <div className="form-group mb-3">
                                <label htmlFor="password">Password</label>
                                <input type="password" placeholder='Enter your password' className='w-100' name="password" value={formData.password} onChange={handleChange} id="password" autoComplete='false' />
                            </div>
                            <div className="form-group mb-3">
                                <label htmlFor="vpassword">Confirm Password</label>
                                <input type="password" placeholder='Verify password' className='w-100' name="verify_password" value={formData.verify_password} onChange={handleChange} id="vpassword" autoComplete='false' />
                            </div>
                            <div className="form-group mb-3">
                                <label htmlFor="phone_no">Phone No.</label>
                                <input type="tel" placeholder='Enter your phone no.' value={formData.phone_no} onChange={handleChange} className='w-100' name="phone_no" id="phone_no" />
                            </div>
                            <div className="form-group mb-3">
                                <button type='submit'>Continue</button>
                            </div>
                            <p className={"reset"}>Already have  account? <span onClick={() => {
                                navigate("/login");
                            }}>Login</span></p>

                        </form>
                    </div>
                    <Toaster
                        position="top-right"
                        reverseOrder={false}
                        gutter={8}
                        containerClassName=""
                        containerStyle={{}}
                        toastOptions={{
                            // Define default options
                            className: '',
                            duration: 2000,
                            // Default options for specific types
                            success: {
                                duration: 3000,
                                theme: {
                                    primary: 'green',
                                    secondary: 'white',
                                },
                                style: {
                                    color: 'white',
                                    background: 'green'
                                }
                            },
                            error: {
                                duration: 2000,
                                theme: {
                                    primary: 'white',
                                    secondary: 'red'
                                },
                                style: {
                                    color: 'white',
                                    background: 'red'
                                }
                            }
                        }}
                    />
                </div>
            </div>
        </>


    )
}

export default CreateAccount