import React, { useEffect, useState } from 'react'
import "./auth.scss";
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import { BASE_URL } from '../../Config';
import Cookies from 'js-cookie';

const Login = () => {
    const [formData, setFormData] = useState(
        {
            email: '',
            password: ''
        }
    )
    const [showLoading, setShowLoading] = useState(false);
    const navigate = useNavigate();
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setShowLoading(true);
        if (formData.email === "" || formData.password === "") {
            toast.error("Please enter your email and password");
            setShowLoading(false);
        } else {

            axios.post(BASE_URL + "/login", formData).then((data) => {
                setShowLoading(false);
                if (data && data.data.status) {
                    // console.log(data.data);
                    Cookies.set("token", data.data.token);
                    Cookies.set("user", data.data.data.user_type);
                    const uid = data.data.data.user_type;
                    if(uid == 1){
                        window.location.href = "/admin";

                    }else{
                        window.location.href = "/home";

        
                    }


                } else {
                    // console.log(data.data.message);
                    toast.error(data.data.message);
                }
            }).catch(error => {
                setShowLoading(false);
                if (error.response) {
                    console.log(error.response.data.message);
                    toast.error(error.response.data.message);
                } else if (error.request) {
                    console.log('Network error. Please check your connection.');
                    toast.error("Network error. Please check your connection.");

                } else {
                    console.log('An error occurred while fetching data.');
                    toast.error("An error occurred while processing the request");
                }

            })

        }
    }

    useEffect(() => {
        const token = Cookies.get('token');
        const uid = Cookies.get("user");
        if (token && token.length > 0) {
            if(uid == 1){
                navigate("/admin");
            }else{
                window.location.href = "/home";

            }
            // console.log('Token: ' + token);

        }
        return;
    }, []);

    return (
        <>
            {showLoading === true ? (<div className="spinner-box">
                <div className="spinner"></div>

            </div>)
                :
                null
            }
            <div className='container-fluids flex justify-center align-items-center'>
                <div className="cardi">
                    <div className="logo text-center">
                        <img src={require("../../assets/images/logo.png")} width={200} height={'auto'} alt="logo" />
                    </div>
                    <div className="card-body">
                        <form onSubmit={handleSubmit}>
                            <div className="form-group mb-3">
                                <label htmlFor="email">Email Address</label>
                                <input type="email" placeholder='Enter your Email Address' className='w-100' name="email" value={formData.email} onChange={handleChange} id="email" />
                            </div>
                            <div className="form-group mb-3">
                                <label htmlFor="password">Password</label>
                                <input type="password" placeholder='Enter your password' className='w-100' name="password" value={formData.password} onChange={handleChange} id="email" />
                            </div>
                            <p className={"reset"} onClick={()=>{navigate('/forgot-password')}}><span>Forgot password?</span></p>
                            <div className="form-group mb-3">
                                <button type='submit'>Continue</button>

                            </div>
                            <p class={"reset"}>Don't have an account? <span onClick={()=>{navigate("/create-account");}}>Sign up</span></p>
                        </form>
                    </div>
                    <Toaster
                        position="top-right"
                        reverseOrder={false}
                        gutter={8}
                        containerClassName=""
                        containerStyle={{}}
                        toastOptions={{
                            // Define default options
                            className: '',
                            duration: 2000,
                            // Default options for specific types
                            success: {
                                duration: 3000,
                                theme: {
                                    primary: 'green',
                                    secondary: 'white',
                                },
                                style: {
                                    color: 'white',
                                    background: 'green'
                                }
                            },
                            error: {
                                duration: 2000,
                                theme: {
                                    primary: 'white',
                                    secondary: 'red'
                                },
                                style: {
                                    color: 'white',
                                    background: 'red'
                                }
                            }
                        }}
                    />
                </div>
            </div>
        </>

    )
}

export default Login